import { useEffect } from 'react';
import { CollisionActionType } from './Experience';
import { JoyStickComponent } from './JoyStickComponent';
import { useStore } from './store/useStore';

export const GameUI = ({
  gameSettings = { targetScore: 0 }
}: {
  gameSettings: { targetScore: number };
}) => {
  const score = useStore((store) => store.score);
  const increaseScore = useStore((store) => store.increaseScore);
  const triggeredActions = useStore((store) =>
    store.triggeredActions.find((action) => {
      return action.type === 'collision';
    })
  );
  const dispatchActions = useStore((store) => store.dispatchActions);
  const joystickBehaviour = useStore((store) =>
    store.behaviours.find((behaviour) => behaviour.trigger === 'joystick')
  );

  useEffect(() => {
    if (triggeredActions && triggeredActions.typeAction) {
      const typeAction: CollisionActionType =
        triggeredActions.typeAction as CollisionActionType;
      typeAction.target.name === 'trigger' && increaseScore();
    }
  }, [triggeredActions, increaseScore]);

  useEffect(() => {
    if (score === gameSettings.targetScore) {
      dispatchActions([
        {
          type: 'collect',
          target_id: ''
        }
      ]);
    }
  }, [score, gameSettings.targetScore, dispatchActions]);

  return (
    <>
      <div
        style={{
          fontSize: '12px',
          position: 'absolute',
          bottom: '12px',
          padding: '10px',
          textAlign: 'left',
          borderRadius: '5px',
          backgroundColor: 'lightgray',
          userSelect: 'none',
          WebkitUserSelect: 'none',
          display: 'inline',
          left: '12px',
          marginRight: '12px'
        }}
      >
        <div style={{ fontSize: '24px' }}>Score: {score}</div>
        <div>Score {gameSettings.targetScore} goals to collect your reward</div>
      </div>

      {joystickBehaviour && (
        <JoyStickComponent joystickBehaviour={joystickBehaviour} />
      )}
    </>
  );
};
