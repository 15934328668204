import { GetState, SetState } from "zustand";
import { ExperienceState } from "./useStore";

export interface GameState {
  score: number,
  increaseScore: () => void,
  resetTime: number,
  initGameSlice: (gameSettings: {resetTime: number}) => void
}
let scoreTime = 0;
export const createGameSlice = (set: SetState<ExperienceState>, get: GetState<ExperienceState>) => ({
  score: 0,
  increaseScore: () => {
    if (scoreTime + get().resetTime < Date.now()) {
      set({ score: get().score + 1 });
      scoreTime = Date.now()
    }
  },
  resetTime: 2000, // in ms
  initGameSlice: (gameSettings: {resetTime: number} = {resetTime: 0}) => {
    set({ resetTime: gameSettings.resetTime });
  }
});
