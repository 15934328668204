import nipplejs from 'nipplejs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Behaviour, PhysicsActionType } from './Experience';
import { BehaviourState } from './store/createBehaviourSlice';
import { useStore } from './store/useStore';

const options: nipplejs.JoystickManagerOptions = {
  mode: 'static',
  // position: { bottom: '60px', right: '60px' }
  position: { top: '50%', left: '50%' },
  dynamicPage: true
};

let joyStickAction: nipplejs.JoystickOutputData | undefined = undefined;

export const JoyStickComponent = ({
  joystickBehaviour
}: {
  joystickBehaviour: Behaviour;
}) => {
  const joyStickManager = useRef<nipplejs.JoystickManager>();
  const joyRef = useRef<HTMLDivElement>(null);

  const placementMode = useStore((state) => state.placementMode);

  const [disableJoystick, setDisableJoystick] = useState<boolean>(
    placementMode || false
  );
  const resetTime = useStore((state) => state.resetTime);

  const dispatchActions = useStore(
    (state: BehaviourState) => state.dispatchActions
  );

  const joyStickOnEnd = useCallback(
    (data, data2) => {
      if (joyStickAction && joyStickAction?.direction) {
        joystickBehaviour.actions.forEach((action) => {
          const forceNormalized = Math.min(joyStickAction!.force, 1);
          /*
            x1 => force applied to left and right
              will be influenced by joystick left/right + force of joystick
              left -> positive force
              right -> negative force
              the force applied to the respective direction is normalized.
              Using the config one can add an additional factor to the normalized force
              */
          let x1 =
            joyStickAction!.direction.y === 'right'
              ? 1
              : -1 * forceNormalized * Math.cos(joyStickAction!.angle.radian);
          x1 *=
            (action.typeAction as PhysicsActionType).forceDirection[0] *
            forceNormalized;

          /*
            y1 =>  force applied up/down
              will be influenced by joystick up/down + force of joystick
              down -> positive force
              up -> negative force
        */
          let y1 =
            (joyStickAction!.direction.y === 'up' ? 1 : -1) *
            forceNormalized *
            Math.sin(joyStickAction!.angle.radian);
          if (y1 < -1) y1 = -1;
          if (y1 > 1) y1 = 1;
          y1 *=
            (action.typeAction as PhysicsActionType).forceDirection[1] *
            forceNormalized;

          const z1 = (action.typeAction as PhysicsActionType).forceDirection[2];

          // calculate hit point
          /*
            x2: hit point of ball left or right using degree + force
            y2: hit point of ball up or down using degree + force
            z2: hit point of ball towards z can be static for now 
              hit point must be limited by max width and height and depth of object

            the hit point can be influenced by an additional config parameter which will be multiplied with the calculated hitpoint.
        */
          const hitPoint = (action.typeAction as PhysicsActionType)
            .hitPoint || [0, 0, 0];
          const x2 =
            forceNormalized *
            Math.cos(joyStickAction!.angle.radian) *
            hitPoint[0];

          const y2 =
            (joyStickAction!.direction.y === 'up' ? -1 : 1) *
            forceNormalized *
            Math.sin(joyStickAction!.angle.radian) *
            hitPoint[1];

          const z2 = 1 * hitPoint[2];
          dispatchActions([
            {
              type: action.type,
              target_id: action.target_id,
              typeAction: {
                forceDirection: [x1, y1, z1],
                hitPoint: [x2, y2, z2]
              }
            }
          ]);
          setDisableJoystick(true);
          setTimeout(() => {
            setDisableJoystick(false);
          }, resetTime);
        });
      }
    },
    [dispatchActions, joystickBehaviour, resetTime]
  );

  useEffect(() => {
    options.zone = joyRef.current || undefined;
    joyStickManager.current = nipplejs.create(options);
    joyStickManager.current.on('move', (ev, data) => {
      joyStickAction = data;
    });
    joyStickManager.current.on('end', joyStickOnEnd);
    return () => {
      joyStickManager.current?.destroy();
    };
  }, [joyStickOnEnd]);

  useEffect(() => {
    setDisableJoystick(placementMode);
  }, [placementMode]);

  return (
    <>
      <div
        id="joystick"
        ref={joyRef}
        style={{
          userSelect: 'none',
          WebkitUserSelect: 'none',

          pointerEvents: disableJoystick ? 'none' : 'unset',
          width: '100px',
          height: '100px',
          position: 'absolute',
          bottom: '10px',
          right: '10px'
        }}
      ></div>
    </>
  );
};
