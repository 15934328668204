import { useEffect } from 'react';
import { BehaviourState } from './store/createBehaviourSlice';
import { useStore } from './store/useStore';

export const CollectComponent = () => {
  const triggeredActions = useStore(
    (state: BehaviourState) => state.triggeredActions
  );
  const collect = useStore((state: BehaviourState) => state.collect);

  useEffect(() => {
    if (!triggeredActions || triggeredActions.length === 0) return;
    triggeredActions.forEach((action) => {
      action.type === 'collect' && collect();
    });
  }, [triggeredActions, collect]);

  return <></>;
};
