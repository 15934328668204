import { useEffect, useState } from 'react';
import './App.css';
import { XRiiArComponent } from './XRiiArComponent';
import * as queryString from 'query-string';

const urlParams = queryString.parse(window.location.search, {
  arrayFormat: 'separator',
  arrayFormatSeparator: '|'
});
const expPath = urlParams['path'];

function App() {
  const [experience, setExperience] = useState<any>();
  useEffect(() => {
    const path = expPath || `${process.env.PUBLIC_URL}/experience.json`;
    fetch(`${path}`)
      .then((response) => response.json())
      .then((data) => setExperience(data));
  }, []);
  return (
    <div className="App" style={{ height: '100vh' }}>
      {experience && (
        <XRiiArComponent
          exp={experience}
          collect={() => console.log('COLLECTED')}
          cancel={() => console.log('CANCELLED')}
        />
      )}
    </div>
  );
}

export default App;
