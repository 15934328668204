import create from 'zustand';
import { AppState, createAppSlice } from './createAppSlice';
import { BehaviourState, createBehaviourSlice } from './createBehaviourSlice';
import { createGameSlice, GameState } from './createGameSlice';

export type ExperienceState = BehaviourState & AppState & GameState;
// create the store to manage the behaviours of 3d objects
export const useStore = create<ExperienceState>((set, get) => {
  return {
    ...createBehaviourSlice(set, get),
    ...createAppSlice(set, get),
    ...createGameSlice(set, get)
  };
});
