import { GetState, SetState } from "zustand";
import { ExperienceState } from "./useStore";

export interface AppState {
  placementMode: boolean,
  arMode: boolean,
  setPlacementMode: (mode: boolean) => void
  setArMode: (mode: boolean) => void
}

export const createAppSlice = (set: SetState<ExperienceState>, get: GetState<ExperienceState>) => ({
  placementMode: false,
  arMode: false,
  setPlacementMode: (mode: boolean) => {set({placementMode: mode})},
  setArMode: (mode: boolean) => {set({arMode: mode})}
});
