import { useEffect, useState } from 'react';
import { DefaultLoadingManager } from 'three';

export const LoadingScreen = ({trackingReady}: {trackingReady?: boolean} = {trackingReady: true}) => {
  const [progress, setProgress] = useState('0');
  const [visible, setVisible] = useState(true);
  const [loaded, setLoaded] = useState(false);

  DefaultLoadingManager.onStart = (item, loaded, total) => {
    setProgress(loaded.toFixed(0));
  };
  let saveLastTotalLoaded = 0;

  DefaultLoadingManager.onProgress = (item, loaded, total) => {
    if (loaded === total) {
      saveLastTotalLoaded = total;
    }
    setProgress(
      (
        ((loaded - saveLastTotalLoaded) / (total - saveLastTotalLoaded)) *
          100 || 100
      ).toFixed(0)
    );

  };

  DefaultLoadingManager.onLoad = () => {
    // setVisible(false)
    setLoaded(true);
  };

  useEffect(() => {
    trackingReady && loaded && setVisible(false);
  }, [trackingReady, loaded]);

  if (!visible) {
    return null;
  }
  return (
    // <Html fullscreen className='html_loader' style={{zIndex: 1000}}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: '#171717',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          transition: 'opacity 100ms ease',
          zIndex: 1000
        }}
      >
        {' '}
        <div
          id="inner"
          style={{
            width: 100,
            height: 3,
            background: '#272727',
            textAlign: 'center'
          }}
        >
          <div id="bar" style={{...styles.bar, transform: `scaleX(${Number.parseFloat(progress) / 100})`}}>
          </div>
          <span
              id="data"
              style={{
                display: 'inline-block',
                position: 'relative',
                fontVariantNumeric: 'tabular-nums',
                marginTop: '0.8em',
                color: '#f0f0f0',
                fontSize: '0.6em',
                fontFamily: `-apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Helvetica Neue", Helvetica, Arial, Roboto, Ubuntu, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
                whiteSpace: 'nowrap'
              }}
            >
              Loading {' '}
              {progress}%
            </span>{' '}
        </div>
      </div>
    // </Html>
  );
};

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#171717',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'opacity 300ms ease',
    zIndex: 1000
  },
  inner: {
    width: 100,
    height: 3,
    background: '#272727',
    textAlign: 'center'
  },
  bar: {
    height: 3,
    width: '100%',
    background: 'white',
    transition: 'transform 200ms',
    transformOrigin: 'left center'
  },
  data: {
    display: 'inline-block',
    position: 'relative',
    fontVariantNumeric: 'tabular-nums',
    marginTop: '0.8em',
    color: '#f0f0f0',
    fontSize: '0.6em',
    fontFamily: `-apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Helvetica Neue", Helvetica, Arial, Roboto, Ubuntu, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    whiteSpace: 'nowrap'
  }
};
