import { GetState, SetState } from "zustand";
import { Action, Behaviour, CallbackActionType } from '../Experience';
import { ExperienceState } from "./useStore";

export interface BehaviourState {
  experience_start: boolean;
  tappedComponent: string;
  tapComponent: (uid: string, nodeName?: string) => void;
  startExperience: () => void;
  triggeredActions: Action[];
  behaviours: Behaviour[];
  collect: (metaData?:any) => void;
  cancel?: () => void;
  init: (behaviour: Behaviour[], collect: CallbackActionType) => void;
  dispatchActions: (actions: Action[]) => void;
}

export const createBehaviourSlice = (set: SetState<ExperienceState>, get: GetState<ExperienceState>) => ({
  behaviours: [],
  experience_start: false,
  tappedComponent: '',
  triggeredActions: [],
  collect: () => { },
  cancel: () => { },
  init: (behaviours: Behaviour[], collect: CallbackActionType) => {
    const loadActions: Action[] = behaviours.flatMap(behaviour => {
      if (behaviour.trigger === 'load') {
        return behaviour.actions;
      }
      return []
    })
    set({ behaviours, collect: collect.collect, triggeredActions: loadActions, cancel: collect.cancel });
  },
  tapComponent: (uid: string, nodeName?: string) => {
    // don't process tap until the start even was fired
    if (!get().experience_start) return;
    const actions = get().behaviours.flatMap(behaviour => {
      // first check if trigger is tap
      if (behaviour.trigger !== "tap") {
        return [];
      }

      // if the behaviour is linked to a node 
      // check if the node name equals the clicked node and if the object_id equals the clicked object
      if (behaviour.node_name) {
        if (behaviour.node_name === nodeName && behaviour.object_id === uid) {
          return behaviour.actions
        }
      } else {
        // no node name specified use just the object_id
        if (behaviour.object_id === uid) {
          return behaviour.actions;
        }
      }
      return []
    })
    set(
      {
        tappedComponent: uid,
        triggeredActions: actions
      }
    )
  },
  startExperience: () => {
    const experienceStartActions = (get().behaviours.filter(behaviour => behaviour.trigger === 'experience_start')).flatMap(b => b.actions);
    set({ experience_start: true, triggeredActions: experienceStartActions });
  },
  dispatchActions: (actions: Action[]) => {
    set({triggeredActions: actions})
  }
})


