import React, { useEffect, useRef, useState } from 'react';
import arHelper from './assets/ar_image.png';
import { Branding } from './Experience';
import * as THREE from 'three';
import { EffectDots } from './background/dots';

interface PermissionGuardProps {
  setPermission: (granted: boolean) => void;
  branding: Branding;
  start: () => void;
}

export const PermissionGuard: React.FC<PermissionGuardProps> = ({
  setPermission,
  branding,
  start
}: PermissionGuardProps) => {
  const [vantaEffect, setVantaEffect] = useState<any>();
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        new EffectDots({
          el: myRef.current,
          THREE
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <div
      style={{ textAlign: 'center', height: '100%', color: '#c2c2c2' }}
      ref={myRef}
    >
      <div style={{ padding: '50px' }}>
        <img
          src={branding.logo}
          style={{
            maxWidth: '60%',
            maxHeight: '50px',
            width: 'auto',
            height: 'auto'
          }}
          alt="Logo"
        ></img>
      </div>
      <h1>Collect your prize</h1>
      <img
        src={arHelper}
        alt="AR helper"
        style={{
          maxWidth: '50%',
          maxHeight: '50%',
          width: 'auto',
          height: 'auto',
          paddingTop: '30px'
        }}
      />
      <div style={{ padding: '50px', paddingBottom: '0px' }}>
        Point the phone towards a flat surface (eg. ground) and push{' '}
        <b>Start</b>.<br />
        <br />
        Your device might promt you for access to camera and sensors. Please{' '}
        <b>allow</b> these.
      </div>
      <br />
      <button
        onClick={() => {
          start();
        }}
        style={{
          backgroundColor: branding.colors.primary,
          color: branding.colors.secondary,
          width: '60%',
          height: '50px',
          borderRadius: '9px',
          border: '0px',
          fontSize: '24px',
          marginTop: '30px'
        }}
      >
        {' '}
        Start{' '}
      </button>
    </div>
  );
};
