import React, { useMemo, useState, Suspense, useEffect } from 'react';
import { PermissionGuard } from './PermissionGuard';
// import { UiComponent } from './UiComponent';
import { setExperience } from './utils';
import { useStore } from './store/useStore';
import { SoundComponent } from './SoundComponent';
import { CollectComponent } from './CollectComponent';
import * as queryString from 'query-string';
import { GameUI } from './GameUI';
import { UiComponent } from './UiComponent';
import * as Zappar from '@zappar/zappar';
import { LoadingScreen } from './LoadingScreen';

const ARComponent = React.lazy(() => import('./ARComponent'));
const ThreeDComponent = React.lazy(() => import('./ThreeDComponent'));

const urlParams = queryString.parse(window.location.search, {
  arrayFormat: 'separator',
  arrayFormatSeparator: '|'
});
const threeD = urlParams['3d'];
export const XRiiArComponent = ({
  exp,
  collect,
  cancel,
}: {
  exp: any;
  collect: (points?: number) => void;
  cancel?: () => void;
}) => {
  const [permissionGranted, setPermissionGranted] = useState<boolean>();
  // const { canShare, takePictureAndShare } = useTakePictureAndShare({
  //   canvasRef
  // });
  // const [arMode, setARMode] = useState<boolean>(false);

  const experience = setExperience(exp);
  const init = useStore((store) => store.init);
  const initGameSlice = useStore((store) => store.initGameSlice);
  const setArMode = useStore((store) => store.setArMode);
  const arMode = useStore((store) => store.arMode);

  useEffect(() => {
    init(experience.behaviours, { collect, cancel }, );
    initGameSlice(experience.gameSettings);
    // Don't want this to rerun on every render
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const soundObjects = useMemo(() => {
    if (!experience.sounds) return;
    return experience.sounds.map((soundConf, key) => {
      return <SoundComponent config={soundConf} key={key} />;
    });
  }, [experience]);
  if (!permissionGranted && !threeD) {
    return (
      <PermissionGuard
        setPermission={setPermissionGranted}
        branding={experience.branding}
        start={() => {
          Zappar.permissionRequest().then((permGranted) => {
            if (permGranted) {
              // User granted the permissions so start the camera
              setArMode(permGranted);
              setPermissionGranted(permGranted);
            } else {
              // User denied the permissions
              // You can show your own 'permission denied' UI here or use Zappar's built-in one
              setArMode(false);
              setPermissionGranted(true);
            }
          });
        }}
      ></PermissionGuard>
    );
  }
  if (!arMode || threeD) {
    setArMode(false);
    return (
      <>
        <CollectComponent />
        <Suspense fallback={<LoadingScreen/>}>
          <ThreeDComponent experience={experience} />
          {soundObjects}
          <UiComponent
            // canShare={canShare}
            // takePictureAndShare={takePictureAndShare}
            branding={experience.branding}
            tutorial={experience.tutorial}
          />
        </Suspense>

        {/* UI can't be part of suspense because it breaks the joyStick */}
        {experience.gameSettings && (
          <GameUI gameSettings={experience.gameSettings} />
        )}
      </>
    );
  }

  return (
    <>
      <CollectComponent />
      <Suspense fallback={<LoadingScreen></LoadingScreen>}>
        <ARComponent experience={experience} />
        {soundObjects}
        <UiComponent
          // canShare={canShare}
          // takePictureAndShare={takePictureAndShare}
          branding={experience.branding}
          tutorial={experience.tutorial}
        />
      </Suspense>
      {experience.gameSettings && (
        <GameUI gameSettings={experience.gameSettings} />
      )}
    </>
  );
};
