import { useEffect } from 'react';
import useSound from 'use-sound';
import { Sound } from './Experience';
import { BehaviourState } from './store/createBehaviourSlice';
import { useStore } from './store/useStore';

export const SoundComponent = ({ config }: { config: Sound }) => {
  const [play, { stop }] = useSound(config.url, {
    volume: config.volume || 0.25,
    loop: config.loops
  });
  const placed = !useStore((store) => store.placementMode);

  const triggeredActions = useStore(
    (state: BehaviourState) => state.triggeredActions
  );

  useEffect(() => {
    if (!placed) {
      stop();
      return;
    }
    if (!triggeredActions || triggeredActions.length === 0) return;
    triggeredActions.forEach((action) => {
      if (action.target_id !== config.uid || action.type !== 'play-sound')
        return;
      // this is our play sound action
      play();
    });
  }, [triggeredActions, placed, config, play, stop]);

  return <></>;
};
