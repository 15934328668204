import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { Branding, Tutorial } from './Experience';
import { useStore } from './store/useStore';
import { useCallback, useEffect, useState } from 'react';
import fscreen from 'fscreen';
import * as queryString from 'query-string';

const ButtonBar = styled.div`
  position: absolute;
  right: 15px;
  top: 150px;
  display: grid;
  grid-row-gap: 15px;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
`;

const FullScreen = styled.div`
  position: absolute;
  right: 15px;
  bottom: 15px;
`;

const Cta = styled.div<{
  visible: boolean;
  colors: { primary: string; secondary: string };
}>`
  position: absolute;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  bottom: 21px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(props) => props.colors.primary};
  color: ${(props) => props.colors.secondary};
  font-size: 24px;
  width: 250px;
  height: 50px;
  border-radius: 9px;
  display: grid;
  align-items: center;
`;

const Hint = styled.div<{show?: boolean, hideTime?: number }>`
  position: absolute;
  bottom: 100px;
  width: 200px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.4);
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  opacity: ${({ show }) => (show ? '1' : '0')};

transition:  ${({hideTime}) => ( `opacity ${hideTime || 500}ms , visibility 0s ${hideTime || 1000}ms`)} ;
visibility: ${({ show, hideTime }) => (show && !(hideTime===0) ? 'visible' : 'hidden')};
`;

const urlParams = queryString.parse(window.location.search, {
  arrayFormat: 'separator',
  arrayFormatSeparator: '|'
});
const standalone = urlParams['standalone'];

interface UiComponentProps {
  canShare?: boolean;
  takePictureAndShare?: () => void;
  branding: Branding;
  tutorial: Tutorial;
}

export const UiComponent = ({
  canShare,
  takePictureAndShare,
  branding,
  tutorial
}: UiComponentProps) => {
  const placementMode = useStore((store) => store.placementMode);
  const [showHint, setShowHint] = useState(true);
  const [hintText, setHintText] = useState('Tap model to place')
  const behaviour = useStore((store) => store.triggeredActions.find(action=> action.type === 'add-force'))
  const cancel = useStore((store) => store.cancel);
  const [inFullscreenMode, setInFullscreenMode] = useState(false);

  const handleFullscreenChange = useCallback((e) => {
    if (fscreen.fullscreenElement !== null) {
      setInFullscreenMode(true);
    } else {
      setInFullscreenMode(false);
    }
  }, []);

  useEffect(() => {
    if (fscreen.fullscreenEnabled) {
      fscreen.addEventListener(
        'fullscreenchange',
        handleFullscreenChange,
        false,
      );
      return () => {
        fscreen.removeEventListener('fullscreenchange', handleFullscreenChange);
      };
    }
  });

  useEffect(() => {
    if (tutorial) {
      if (!placementMode) {
        setHintText(tutorial.text);
      }
    }
  }, [placementMode, tutorial]);

  useEffect(() => {
    if (behaviour) {
      setShowHint(false);
    }
  },[behaviour])

  const fullscreen = useCallback(() => {
    if (!inFullscreenMode) {
    fscreen.requestFullscreen(document.documentElement);
    } else {
      fscreen.exitFullscreen();
    }
  },[inFullscreenMode])

  if (standalone) {
return(<>
    <FullScreen id='fullscreen' >
      <Icon icon="ion:scan" color="white" width="28px" onClick={fullscreen}/>
    </FullScreen></>
)
  }

  return (
    <>
    <CloseButton id='close'>
    <Icon
            icon="ion:close"
            color="white"
            width="28px"
            onClick={cancel}
          />
    </CloseButton>

      <div
        style={{
          top: '50px',
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)'
        }}
      >
        <img
          src={branding.logo}
          style={{
            maxWidth: '60%',
            maxHeight: '50px',
            width: 'auto',
            height: 'auto'
          }}
          alt="Logo"
        ></img>
      </div>
      {/* {placementMode && ( */}
        <Hint show={showHint} hideTime={tutorial?.visible || 0}
          role="button"
        >
          {hintText}
        </Hint>
      {/* )} */}
      <ButtonBar>
        {/* <Icon
          icon="ion:reload"
          color="white"
          width="28px"
          onClick={() => setPlacementMode(true)}
        /> */}
        {canShare && (
          <Icon
            icon="ion:share-outline"
            color="white"
            width="28px"
            onClick={takePictureAndShare}
          />
        )}
      </ButtonBar>
      {branding.has_cta_button && (
        <Cta visible={!placementMode} colors={branding.colors}>
          {branding.cta_text}
        </Cta>
      )}
    </>
  );
};
