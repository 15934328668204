import { Experience } from './Experience';

export const setExperience = (exp?: any) => {
  // Experience mapping can go here and setting default values
  exp.camera = exp.camera ? exp.camera : {};
  exp.camera.ar = exp.camera.ar ? exp.camera.ar : { position: [0, 0, -10] };
  exp.camera['3d'] = exp.camera['3d']
    ? exp.camera['3d']
    : { position: [0, 10, 12] };

    // per default object type is gltf
    exp.objects.forEach((object: any) => {
      object.type = object.type ? object.type : object.type = 'gltf';
    });
  return exp as Experience;
};
